
import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'
import type { C_Addresslist, DS_Addresslist } from '../../../types'

const langsMap = {
  Title: 'SHEIN_KEY_PWA_15670',
  AddText: 'SHEIN_KEY_PWA_15137',
  ConfirmTitle: 'SHEIN_KEY_PWA_15224',
  ConfirmDelete: 'SHEIN_KEY_PWA_15146',
  ConfirmCancel: 'SHEIN_KEY_PWA_15729',
  Collection: 'SHEIN_KEY_PWA_17662',
  Default: 'SHEIN_KEY_PWA_15376',
  Home: 'SHEIN_KEY_PWA_15127',
  Work: 'SHEIN_KEY_PWA_15128',
  Edit: 'SHEIN_KEY_PWA_15197',
  NotShippingDesc: 'SHEIN_KEY_PWA_16528',
  UpperLimit: 'SHEIN_KEY_PWA_15864',
}

export const getAddressList: DS_Addresslist.APIS_THIS['getAddressList'] = async function (x) {
  const result = await this.$schttp<C_Addresslist.AddressListResponse>({
    url: '/address/address_list',
    params: x,
  })
  return result.data
}

export const langs: DS_Addresslist.APIS_THIS['langs'] = async function() {
  if (sessionStorage.getItem('langs')) {
    return JSON.parse(sessionStorage.getItem('langs') as string)
  }
  const result = await getLangs<C_Addresslist.LangKeys>(langsMap, this.$schttp)
  sessionStorage.setItem('langs', JSON.stringify(result))
  return result
}
