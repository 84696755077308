import type { RES_CouponList } from '../../../types/index'
import { isToLowerCase } from '../../../common/utils/index'
import useState from './useState.js'
const { commonCouponListRef, bestCouponListRef, addedCouponListRef, primeCouponListRef, disabledCouponListRef } = useState
export const useCoupon = () => {

  /**
   * @description 手动用券后，滚动到券对应位置
   * @param {coupon}
   * @returns {viold}
   */
  const handleAutoAnchored = (coupon) => {
    const availableCouponList: RES_CouponList.availableCouponList = bestCouponListRef.value?.concat(commonCouponListRef.value || [])
    const keyId = availableCouponList?.find(i => isToLowerCase(i.coupon, coupon))?.keyId
    const couponItemEl = document.getElementById(`coupon-item-${keyId}`)
    couponItemEl?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const hasProperty = (obj, key) => {
    return Object.prototype.hasOwnProperty.call(obj, key)
  }

  /**
   * @description 在对应券分类的item.businessExtension.order.questionMarkInfo.couponAvaliableGoodsList插入商品数据
   * @param {classifyCouponList, id, goods}
   * @returns {classifyCouponList}
   */
  const insertGoodsOnClassify = (classifyCouponList, id, goods = []) => {
    if(!classifyCouponList || classifyCouponList?.length <= 0) {
      return classifyCouponList
    }
    const classifyCouponListCp = JSON.parse(JSON.stringify(classifyCouponList)) || []
    classifyCouponListCp.forEach(item => {
      if (id == item?.id) {
        hasProperty(item, 'businessExtension')
        && hasProperty(item.businessExtension, 'order')
        && hasProperty(item.businessExtension.order, 'questionMarkInfo')
        && (item.businessExtension.order.questionMarkInfo['couponAvaliableGoodsList'] = goods)
      }
    })
    console.log('classifyCouponList.value:',classifyCouponListCp)
    return classifyCouponListCp
  }

  /**
   * @description 券分类插入商品数据
   * @param {id, goods}
   * @returns {viold}
   */
  const insertCouponGoodsUsable = (id, goods = []) => {
    commonCouponListRef.value = insertGoodsOnClassify(commonCouponListRef.value, id, goods)
    addedCouponListRef.value = insertGoodsOnClassify(addedCouponListRef.value, id, goods)
    primeCouponListRef.value = insertGoodsOnClassify(primeCouponListRef.value, id, goods)
    bestCouponListRef.value = insertGoodsOnClassify(bestCouponListRef.value, id, goods)
    // disabledCouponListRef.value = insertGoodsOnClassify(disabledCouponListRef.value, id, goods)
  }

  /**
   * @description 券分类插入受限商品数据
   * @param {id, goods}
   * @returns {viold}
   */
  const insertCouponLimitGoods = (id, goods = []) => {
    const disabledCouponListCp = JSON.parse(JSON.stringify(disabledCouponListRef?.value)) || []
    disabledCouponListCp.forEach(item => {
      if (id == item?.id) {
        item.addModule && item.addModule.content && (item.addModule.content.couponGoodsList = goods)
      }
    })
    disabledCouponListRef.value = disabledCouponListCp
    console.log('disabledCouponListRef.value:',disabledCouponListRef.value)
  }

  // 用券后需要滚动到券对应位置
  const newApplyCouponCB = (couponCodeRefVal) => {
    const couponWrapEl = document?.getElementById('bsc-coupon-list__drawer')?.querySelector('.S-drawer__body')
    const { clientHeight = 0, scrollTop = 0 } = couponWrapEl as HTMLElement
    const couponItemEl = document?.getElementById(`coupon-item-${couponCodeRefVal}`)
    const itemScrollHeight = scrollTop + (couponItemEl as HTMLElement)?.offsetTop - (clientHeight / 2)
    couponWrapEl?.scrollTo(0, itemScrollHeight)
  }

  /**
   * @description 获取已使用券的券
   * @param {id, goods}
   * @returns {viold}
   */
  const getCouponArrFromSucc = (applyCouponsSuccess) => {
    return applyCouponsSuccess?.map(i => {
      return i.couponCode || i.coupon
    })
  }

  return {
    handleAutoAnchored,
    insertCouponGoodsUsable,
    insertCouponLimitGoods,
    newApplyCouponCB,
    getCouponArrFromSucc,
  }
}
