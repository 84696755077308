<template>
  <!-- Tip: 每单限用一张券 -->
  <SAlert
    v-if="noticeTipsShow && couponListNotice"
    class="bsc-coupon-list__notice-tip"
    type="warning"
    closable
    :max-rows="2"
    :show-icon="true"
    @close="closeNotice"
  >
    <div
      class="value"
      tabindex="0"
    >
      <p v-html="escapeHtml(couponListNotice)"></p>
    </div>
  </SAlert>
</template>
<script name="notice-tip" setup lang="ts">
  import { defineProps, ref } from 'vue'
  import { Alert as SAlert } from '@shein/sui-mobile'

  const noticeTipsShow = ref(true)

  const closeNotice = () => {
    noticeTipsShow.value = false
  }

  defineProps<{
    couponListNotice: string,
  }>()

  function escapeHtml(html: string): string {
    return html.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&#039;')
  }
</script>
<style lang="less">
  .bsc-coupon-list__notice-tip {
    padding: 0.32rem
  }
</style>
