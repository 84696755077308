import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_Addresslist } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_Addresslist.SourceParams): DS_Addresslist.APIS {
  return organizeDataSource<DS_Addresslist.APIS, DS_Addresslist.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_Addresslist.FS_SourceParams) {
  const { getAddressList, langs } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_Addresslist.FS_APIS, DS_Addresslist.FS_DATA>({
    getAddressList,
    langs,
  })
}
