export function isToLowerCase(a, b) {
  return a?.toLowerCase() == b?.toLowerCase()
}

/**
 * 获取支付限制
 * @param {Array} currencySupport 支付方式币种配置
 * @param {String} currency 当前币种
 * @param {String} paymentCode 支付方式编码，如 oxxo, paypal ..
 * @param {String} countryCode 运输国家简码，如 US, MX ..
 * ---------------
 * @returns {Boolean} isChangeCurrency 是否需要切换币种
 * @returns {Object} currentPayment 当前支付方式的币种配置
 */
export function handleSwitchCurrency ({ currencySupport = [], currency, paymentCode, countryCode }) {
  // 强制切换币种
  let isChangeCurrency = false
  let currentPayment = null // 当前可用的支付配置
  let currentPaymentWithoutCountryCode = null // 当不限制国家时的支付方式配置

  if (paymentCode) {
    // 获取当前支付方式配置
    const currentPaymentSupports = currencySupport.filter(item => item.payMethod == paymentCode)
    if (currentPaymentSupports.length == 0) {
      return {
        isChangeCurrency,
        currentPayment,
      }
    }

    for (const i in currentPaymentSupports) {
      if (currentPaymentSupports[i].countryCode == countryCode && countryCode != '') {
        // 有限制国家的配置，直接取，跳出循环
        currentPayment = currentPaymentSupports[i]
        break
      }
      if (currentPaymentSupports[i].countryCode === null) {
        // 非国家限制，兜底
        currentPaymentWithoutCountryCode = currentPaymentSupports[i]
      }
    }

    // 优先取有国家限制的支付方式，没有就取无国家限制的
    currentPayment = currentPayment || currentPaymentWithoutCountryCode

    if (currentPayment) {
      const { currencySupportList = [], defaultCurrency } = currentPayment
      const isSupport = currencySupportList.some(item => item == currency)

      // 若不支持此币种
      if (!isSupport && defaultCurrency) {
        isChangeCurrency = true
      }
    }
  }

  return {
    isChangeCurrency,
    currentPayment,
  }
}
