import { ref, watch, reactive } from 'vue'
import useState from './useState.ts'
import { useCoupon } from './useCoupon.ts'

const { cacheTargeCode } = useState
const { newApplyCouponCB } = useCoupon()

export const useRenewCouponList = (visible) => {
  const isShowCouponList = ref(visible.value)

  const handleDestroyList = () => {
    isShowCouponList.value = false
    cacheTargeCode.value && (cacheTargeCode.value = '')
  }

  watch(visible, (val: boolean) => {
    if(!val) {
      return
    }

    setTimeout(() => {
      isShowCouponList.value = true
      setTimeout(() => {
        cacheTargeCode.value && newApplyCouponCB(cacheTargeCode.value)
      }, 0)
    }, 0)
  }, {
    immediate: true,
  })

  return {
    isShowCouponList,
    handleDestroyList,
  }
}

export const useTabLoaded = (isFirstTabSelected, visible) => {
  const isTabLoaded = reactive({
    tab1: isFirstTabSelected.value,
    tab2: !isFirstTabSelected.value,
  })

  const handleTabSwitch = (selected) => {
    const isFirstTabSelected = selected === true
    if(!isFirstTabSelected && !isTabLoaded.tab2) {
      // console.debug('[useRenewCouponList] set tab2 ', true)
      isTabLoaded.tab2 = true
    }

    if(isFirstTabSelected && !isTabLoaded.tab1) {
      // console.debug('[useRenewCouponList] set tab1 ', true)
      isTabLoaded.tab1 = true
    }
  }

  watch(visible, (val) => {
    // 关闭弹窗后未重置选中状态
    const isFirstSelected = isFirstTabSelected.value === true
    if(!val) {
      isTabLoaded.tab1 = isFirstSelected
      isTabLoaded.tab2 = !isFirstSelected
      // console.debug('[useRenewCouponList] reset tab ', isFirstSelected, !isFirstSelected)
    }
  })

  return {
    isTabLoaded, handleTabSwitch,
  }
}
