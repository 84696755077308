<template>
  <SDrawer
    id="bsc-coupon-list__drawer"
    :visible="visibleMyCouponListModal"
    class="bsc-coupon-list__drawer"
    type="multi"
    direction="rtl"
    :append-to-body="true"
    :immediately-render="true"
    :no-header="true"
    @closed="handleDestroyList"
  >
    <div v-cloak>
      <DrawerTitle
        :title="title || 'My Coupons'"
        :isHideBackIcon="isHideBackIcon || false"
        @go-back="emit('go-back')"
      />

      <slot name="title-right-side">
        <Icon
          class="bsc-coupon-list__icon-close"
          name="sui_icon_close_20px"
          size="20px"
          @click.native="emit('handle-click-close')"
        />
      </slot>

      <!-- 列表主体 -->
      <mainCouponList
        ref="mainCouponListRef"
        :visible="visible"
        :language="language"
        :errinfo="errinfo"
        :isFreePrice="isFreePrice"
        :checkoutNo="checkoutNo"
        :isCouponAutoUse="isCouponAutoUse"
        :applyCouponsSuccess="applyCouponsSuccess"
        :selectedPaymentInfo="selectedPaymentInfo"
        :selectedShipMethod="selectedShipMethod"
        :saveCardProductCode="saveCardProductCode"
        :primeProductCode="primeProductCode"
        :coupon-list-url="couponListUrl"
        :coupon-list-scene="couponListScene"
        :isShowCouponList="isShowCouponList"
        @handle-best-apply-btn="handleBestApplyBtn"
        @handle-new-apply-btn="handleNewApplyBtn"
        @change-confirm-coupon="changeConfirmCoupon"
        @change-loading="changeLoading"
        @change-tab="changeTab"
        @handle-gift--coupon-operation="handleGiftCouponOperation"
      >
        <template
          #new-coupon-item="{
            data,
            couponConfig,
            index,
            checkoutCouponIndex,
            couponType,
          }"
        >
          <slot
            name="new-coupon-item"
            v-bind="{
              data,
              couponConfig,
              index,
              checkoutCouponIndex,
              couponType,
            }"
          ></slot>
        </template>
      </mainCouponList>
    </div>
    <template
      v-if="isShowCouponUseInfo"
      #footer
    >
      <CouponUseInfo
        :is-show-coupon-use-info="isShowCouponUseInfo"
        :is-free-price="isFreePrice"
        :applyCouponsSuccess="applyCouponsSuccess"
        :language="language"
        :couponTotalPrice="couponTotalPrice"
        @confirm-use-coupon-handle="confirmUseCouponHandle"
      />
    </template>
    <Loading
      v-if="isShowLoading && visibleMyCouponListModal"
      :show="isShowLoading"
      :mask-transparent="true"
      type="curpage"
    />
  </SDrawer>
</template>

<script name="BCouponList" setup lang="ts">
import { ref, computed, watch, defineExpose, defineEmits, nextTick, onMounted, withDefaults, provide } from 'vue'
import { Drawer as SDrawer, Loading } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
// 数据源：
import type { AS_CouponList, DS_CouponList, RES_CouponList, PROP_CouponList } from '../../types'
import { COUPON_LIST_SCENE } from '../../common/utils/constants'

import CouponUseInfo from './components/coupon-use-info.vue'
import DrawerTitle from './components/drawer-title.vue'
import mainCouponList from './components/main-coupon-list.vue'
import { useAnalysis } from '../../common/analysisSource'
import useState from './hooks/useState.ts'
import useApis from '../../hooks/useApis'

import useDrawer from './hooks/useDrawer'
import {useRenewCouponList} from './hooks/useRenewCouponList.ts'

const { handlePageOnload, isShowLoading, changeLoading, confirmUseCoupon, changeConfirmCoupon } = useDrawer
const { langs } = useApis()
const { usableCouponList } = useState

interface CouponListComponent {
  getCouponList: (opt) => void;
  insertCouponGoodsUsable: (id: string, goods: Array<object>) => void;
  insertCouponLimitGoods: (id: string, goods: Array<object>) => void;
  scrollToTargeCoupon: (code: string) => void;
}
const emit = defineEmits([
  'confirm-use-coupon-handle',
  'go-back',
  'handle-click-close',
  'handle-best-apply-btn',
  'handle-new-apply-btn',
  'handle-gift--coupon-operation',
])
type propsType = {
  isAutoFetchApi?: boolean,
  couponListScene?: string, // 使用优惠券列表场景
  visible?: boolean,
  isHideBackIcon?: boolean | undefined,
  title?: string | null | undefined,
  isFreePrice?: boolean,
  isCouponAutoUse?: boolean,
  applyCouponsSuccess?: Array<RES_CouponList.CommonCoupon> | [] | undefined | null,
  checkoutNo?: string | null, // 立即购单号（为空默认走正常下单逻辑）
  errinfo?: string,
  couponTotalPrice?: PROP_CouponList.Price,
  selectedPaymentInfo?: PROP_CouponList.SelectedPaymentInfo | undefined,
  selectedShipMethod?: PROP_CouponList.SelectedPaymentInfo | undefined,
  saveCardProductCode?: string,
  primeProductCode?: string,
  couponListUrl?: string, // 优惠券列表接口地址

  fsData?: DS_CouponList.FS_DATA
  dataSource?: DS_CouponList.DataSource
  analysisSource?: AS_CouponList.AnalysisSource
}

const props = withDefaults(defineProps<propsType>(), {
  isAutoFetchApi: true,
  couponListScene: COUPON_LIST_SCENE.NOMAL_CHECKOUT,
  visible: false,
  isHideBackIcon: false,
  lang: 'us',
  title: 'My Coupons',
  isFreePrice: false,
  isCouponAutoUse: false,
  applyCouponsSuccess: null,
  saveCardProductCode: '',
  primeProductCode: '',
  checkoutNo: '',
  errinfo: '',
  couponListUrl: 'order/coupon/list',
})

let analysisInstance,triggerNotice
analysisInstance = useAnalysis()
provide('analysisInstance', analysisInstance)
triggerNotice = analysisInstance?.triggerNotice

// refs
const language = ref(null)
const visibleMyCouponListModal = ref(false)
const unusedCouponShow = ref(true)
// const isFreeShippingRef = ref(false)
// const checkCouponsRef: Ref<RES_CouponList.CheckCouponsItem[] | null> = ref([])
const mainCouponListRef = ref(null)

const { isShowCouponList, handleDestroyList } = useRenewCouponList(visibleMyCouponListModal)

onMounted(() => {
  getLangs()
})

const getCouponList = (option) => {
  if (mainCouponListRef.value) {
    (mainCouponListRef.value as CouponListComponent).getCouponList(option)
  }
}

watch(() => props.visible, (val) => {
  visibleMyCouponListModal.value = val
  console.log('coupon___val', val)

  nextTick(() => {
    val && props?.isAutoFetchApi && getCouponList({})
    handlePageOnload(val, triggerNotice, usableCouponList)
  })

})

const isShowCouponUseInfo = computed(() => {
  return confirmUseCoupon.value && unusedCouponShow.value && props.applyCouponsSuccess && props.applyCouponsSuccess.length
})

//需要查看是否下单接口直接用字段就行
// const showGiftTip = computed(() => {
//   return checkCouponsRef.value?.filter(item => {
//     return item?.other_coupon_rule?.find(item => item.satisfied_max_range && item.coupon_gift_id)
//   }).length
// })

/**************************** methods *****************/

const changeTab = (payload) => {
  unusedCouponShow.value = payload
}

//获取多语言
const getLangs = () => {
  langs().then(res => {
    language.value = res
  })
}
const confirmUseCouponHandle = () => {
  emit('confirm-use-coupon-handle')
}

const handleBestApplyBtn = (coupon, type) => {
  emit('handle-best-apply-btn', coupon, type)
}
const handleNewApplyBtn = (payload) => {
  emit('handle-new-apply-btn', payload)
}

const handleGiftCouponOperation = () => {
  emit('handle-gift--coupon-operation')
}

const getConfirmModelStatus = () => {
  return confirmUseCoupon.value
}

const insertCouponGoodsUsable = (id, goods = []) => {
  if (mainCouponListRef.value) {
    (mainCouponListRef.value as CouponListComponent).insertCouponGoodsUsable(id, goods)
  }
}
const insertCouponLimitGoods = (id, goods = []) => {
  if (mainCouponListRef.value) {
    (mainCouponListRef.value as CouponListComponent).insertCouponLimitGoods(id, goods)
  }
}

const scrollToTargeCoupon = (code) => {
  if (mainCouponListRef.value) {
    (mainCouponListRef.value as CouponListComponent).scrollToTargeCoupon(code)
  }
}

defineExpose({
  getCouponList,
  scrollToTargeCoupon,
  insertCouponLimitGoods,
  insertCouponGoodsUsable,
  getConfirmModelStatus,
  changeLoading,
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
 transition: all 0.5s;
}

.bsc-coupon-list__drawer {
  .S-drawer__container{
    width: 100%;
    max-width: 100% !important;
  }
  .S-drawer__multi-footer {
    border-top: 1px solid #e5e5e5;
    z-index: 3; // 券组件部分元素z-index=3，这里需要覆盖券组件元素
  }
  &__icon-close {
    height: 1.17rem;
    font-size: 16px;
    padding: 0 0.32rem;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translate3d(0, 0, 10px);
  }
}
</style>
