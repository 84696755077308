<template>
  <div class="checkout-price-detail">
    <div
      v-for="item in sortedPrice"
      :key="item.type"
      class="checkout-price-detail__item"
    >
      <div
        class="checkout-price-detail__item-parent checkout-price-detail__flex"
        :style="priceItemBorder(item)"
        @click="toggleSubShowStatus(item)"
      >
        <div class="checkout-price-detail__item-left">
          <div class="checkout-price-detail__item-left-name">
            <span>
              {{ item.local_name }}
            </span>
            <!-- 大件运费弹窗提示 -->
            <Icon
              v-if="isShowShippingFee && item.type == 'shipping'"
              name="sui_icon_doubt_12px_2"
              size="14px"
              color="#959595"
              @click.stop="clickShippingFeeIcon"
            />
            <Icon
              v-else-if="!!item.tip || !!item.des"
              name="sui_icon_doubt_12px_2"
              size="14px"
              color="#959595"
              @click.stop="clickIcon(item.tip || item.des)"
            />
            <span
              v-if="!!item.description"
              class="checkout-price-detail__item-left-des"
              :style="descriptionStyle(item)"
            >
              {{ item.description }}
            </span>
          </div>
          <div
            v-if="!!item.below_desc?.length"
            class="checkout-price-detail__item-left-below"
          >
            <span
              v-for="(below, inx) in item.below_desc"
              :key="inx"
              :style="belowStyle(below)"
            >
              {{ below.desc }}
            </span>
          </div>
        </div>
        <div class="checkout-price-detail__item-right">
          <del
            v-if="!!item.origin_price_with_symbol"
            class="checkout-price-detail__item-origin"
          >
            {{ item.origin_price_with_symbol }}
          </del>
          <img
            v-if="!!item.price_icon"
            class="checkout-price-detail__item-img"
            :src="item.price_icon"
            alt=""
          />
          <span
            class="checkout-price-detail__item-price"
            :style="handleActualPrice(item).style"
          >
            {{ handleActualPrice(item).text }}
          </span>
          <Icon
            v-if="handleChildInfo(item).show && handleChildInfo(item).showIcon"
            :key="`${item.type}_${handleSubShowStatus(item)?.icon}`"
            :name="handleSubShowStatus(item)?.icon"
            size="12px"
            color="#959595"
          />
        </div>
      </div>
      <template v-if="handleChildInfo(item).show">
        <template v-if="handleChildInfo(item).type == 'sub'">
          <div
            v-if="hasClickedSub"
            v-show="handleSubShowStatus(item)?.showSub"
            class="checkout-price-detail__item-child checkout-price-detail__item-sub"
          >
            <div
              v-for="child in item.sub_price_details"
              :key="child.local_name"
              class="checkout-price-detail__flex checkout-price-detail__child-item"
            >
              <div class="checkout-price-detail__item-left">
                <span>
                  {{ child.local_name }}
                </span>
                <Icon
                  v-if="!!child.tip || !!child.des"
                  name="sui_icon_doubt_12px_2"
                  size="14px"
                  color="#959595"
                  @click.stop="clickIcon(child.tip || child.des)"
                />
              </div>
              <div class="checkout-price-detail__item-right">
                {{ child.price_with_symbol }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-if="hasClickedSub"
            v-show="handleSubShowStatus(item)?.showSub"
            class="checkout-price-detail__item-child checkout-price-detail__item-discount"
          >
            <DiscountItem
              v-for="(child, index) in item.discount_detail_list"
              :key="index"
              :child="child"
              :appendToBody="!isInDrawer"
            />
          </div>
        </template>
      </template>
    </div>
    <div
      class="checkout-price-detail__total checkout-price-detail__flex"
      :class="totalStyle ? 'checkout-price-detail__flex-end' : 'checkout-price-detail__total-old'"
    >
      <div class="checkout-price-detail__total-left">
        {{ totalName }}
      </div>
      <div
        ref="totalRightPriceWrapperRef"
        class="checkout-price-detail__total-right"
      >
        <div
          v-if="!!savePriceTips"
          class="checkout-price-detail__total-save"
        >
          {{ savePriceTips }}
        </div>
        <div class="checkout-price-detail__total-amount">
          <span class="checkout-price-detail__total-amount-all">
            {{ totalPrice }}
          </span>
          <span
            v-if="!!govTaxPrice"
            class="checkout-price-detail__total-amount-gov"
          >
            +{{ govTaxPrice }}
          </span>
        </div>
      </div>
    </div>

    <!-- 点击icon提示文案弹窗 -->
    <LazyMount>
      <SDialog
        class="checkout-price-detail__dialog"
        :visible="tipsVisible"
        append-to-body
        :show-close="true"
        :close-on-click-modal="false"
        @close="tipsVisible = false"
      >
        <p v-html="tipsContent"></p>
        <template #footer>
          <SButton
            :type="['H72PX', 'primary']"
            :width="'100%'"
            @click="tipsVisible = false"
          >
            {{ dialogBtnText }}
          </SButton>
        </template>
      </SDialog>
    </LazyMount>
  </div>
</template>

<script name="BCheckoutPrice" setup lang="ts">
// 数据源：
import type { C_CheckoutPrice } from '../../types'
import { computed, ref, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Button as SButton, Dialog as SDialog, LazyMount } from '@shein/sui-mobile'
import DiscountItem from './components/discount-item.vue'


interface PropsDefault {
  originSortedPrice: C_CheckoutPrice.OriginSortedPrice,
  sortedPriceStyle: C_CheckoutPrice.SortedPriceStyle,
  scene: C_CheckoutPrice.Scene,
  isShowShippingFee?: C_CheckoutPrice.IsShowShippingFee,
  isInDrawer?: C_CheckoutPrice.IsInDrawer,
  totalStyle?: C_CheckoutPrice.TotalStyle,
  grandTotalTitle: C_CheckoutPrice.GrandTotalTitle,
  taxInclusiveTip?: C_CheckoutPrice.TaxInclusiveTip,
  savePriceTips?: C_CheckoutPrice.SavePriceTips,
  totalPrice: C_CheckoutPrice.TotalPrice,
  govTaxPrice?: C_CheckoutPrice.GovTaxPrice,
  dialogBtnText: C_CheckoutPrice.DialogBtnText,
}

const props = withDefaults( defineProps<PropsDefault>(), {
  originSortedPrice: () => ({} as PropsDefault['originSortedPrice']),
  scene: 'checkout',
  isShowShippingFee: false,
  isInDrawer: false,
  totalStyle: false,
  grandTotalTitle: '',
  taxInclusiveTip: '',
  savePriceTips: '',
  totalPrice: '',
  govTaxPrice: '',
  dialogBtnText: '',
})


const sortedPrice = computed(() => {
  const list = props.originSortedPrice?.filter(_ => _.show == 1)
  return list
})

const totalName = computed(() => {
  const name = props.taxInclusiveTip ? props.grandTotalTitle + props.taxInclusiveTip : props.grandTotalTitle
  return name + ':'
})


const emit = defineEmits(['click-shipping-fee-icon'])
const clickShippingFeeIcon = () => {
  emit('click-shipping-fee-icon')
}

// 是否展示下边框
const priceItemBorder = (item) => {
  return item.show_type?.includes('underline_split') ? {
    'border-bottom': '1px solid #e5e5e5',
    'padding-bottom': '8px',
  } : {
    'border-bottom': 'none',
  }
}

const descriptionStyle = (item) => {
  const colorCode = item.description_color_code
  return {
    color: colorCode ?? '#959595',
  }
}

const belowStyle = item => {
  return {
    color: '#767676',
    textDecoration: item.show_type?.includes('delete_line') ? 'line-through' : 'none',
  }
}

/**
 * 实际售价：price_with_symbol（通过show_type判断具体样式）
 * show_type枚举值：
 * 置灰GRAY("gray_show"),
 * 删除线DELETE("delete_line"),
 * 负号展示NEGATIVE("negative_price"),
 * 红色RED("red_show"),
 * GREEN("green_show"),
 * 付费会员色Prime_Color("prime_color_show"),
 * 下划线Underline_Split("underline_split")(整个item下方有border)
 */
const handleActualPrice = (item) => {
  const price = item.show_type?.includes('negative_price') ? `-${item.price_with_symbol}` : item.price_with_symbol

  let color = '#222'
  if(item.show_type?.includes('gray_show')) {
    color = '#767676;'
  } else if(item.show_type?.includes('red_show')) {
    color = '#FA6338'
  } else if(item.show_type?.includes('green_show')) {
    color = '#198055'
  } else if(item.show_type?.includes('prime_color_show')) {
    color = '#C96E3F'
  }

  const decoration = item.show_type?.includes('delete_line') ? 'line-through' : 'none'

  return {
    text: price,
    style: {
      'color': color,
      'text-decoration': decoration,
    },
  }
}

// 处理子项内容
const handleChildInfo = (item) => {
  /**
   * 枚举值
   * 0 老样式（无子项）；
   * 1、2有子项，可以折叠/展开的样式（子项取sub_price_details）；（1默认展开，没有折叠箭头，不可点击；2可以折叠展开）
   * 3 存在promotion、coupon促销相关信息（子项取discount_detail_list）
   */
  if(![1, 2, 3].includes(+item.display_type)) {
    return {
      show: false,
      list: [],
      type: '',
    }
  }

  let subList
  switch (+item.display_type) {
    case 1:
      subList = item.sub_price_details ?? []
      return {
        show: !!subList.length,
        showIcon: false,
        list: subList,
        type: 'sub',
      }
    case 2:
      subList = item.sub_price_details ?? []
      return {
        show: !!subList.length,
        showIcon: true,
        list: subList,
        type: 'sub',
      }
    case 3:
      subList = item.discount_detail_list ?? []
      return {
        show: !!subList.length,
        showIcon: true,
        list: subList,
        type: 'discount',
      }
    default:
      return {
        show: false,
        showIcon: false,
        list: [],
        type: '',
      }
  }
}


const showSubTotalDetailsList = ref([] as any)
const handleSubTotalDetails = ({ list = [], isInit = false, type = '' }) => {
  if(isInit) {
    list.forEach(item => {
      const isExist = showSubTotalDetailsList.value.find(_ => _.type == item.type)
      if(!isExist) {
        // 存在默认展开逻辑
        const hasShowAll = +item.display_type === 1 && !!item.sub_price_details?.length
        showSubTotalDetailsList.value.push({
          type: item.type,
          showSub: hasShowAll ? true : false,
          icon: 'sui_icon_more_down_12px',
        })

        // 存在默认展开逻辑
        if(hasShowAll) hasClickedSub.value = true
      }
    })
  } else {
    if(!type) return

    showSubTotalDetailsList.value = showSubTotalDetailsList.value.map(item => {
      if(item.type == type) {
        return {
          type,
          showSub: !item.showSub,
          icon: !item.showSub ? 'sui_icon_more_up_12px' : 'sui_icon_more_down_12px',
        }
      }
      return item
    })
  }
}

const handleSubShowStatus = (item) => {
  const currItem = showSubTotalDetailsList.value.find(_ => _.type == item.type)
  return currItem ? {
    showSub: currItem.showSub,
    icon: currItem.icon,
  } : {
    showSub: '',
    icon: '',
  }
}

const hasClickedSub = ref(false)
// 点击展开子项
const toggleSubShowStatus = (item) => {
  const { show: hasSubDetail, showIcon } = handleChildInfo(item)
  // 默认展开的取消点击事件
  if(hasSubDetail && showIcon) {
    hasClickedSub.value = true
    handleSubTotalDetails({ type: item.type })
  }
}

watch(
  () => sortedPrice.value,
  (n, o) => {
    if(n != o) handleSubTotalDetails({ list: n, isInit: true })
  },
  {
    immediate: true,
    deep: true,
  },
)



const tipsVisible = ref(false)
const tipsContent = ref('')
const clickIcon = (text) => {
  tipsVisible.value = true
  tipsContent.value = text
}
// 数据源：END

</script>

<style lang="less">
.checkout-price-detail {
  padding: 16/75rem 24/75rem;
  background: #fff;
  color: #222;

  .checkout-price-detail__item {
    font-size: 14px;
    padding: 16/75rem 0;

    &:first-child{
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  .checkout-price-detail__flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .checkout-price-detail__item-left {
    margin-right: 24/75rem;
  }

  .checkout-price-detail__item-origin {
    color: #959595;
    margin-right: 8/75rem;
  }

  .checkout-price-detail__item-right {
    white-space: nowrap;
  }

  .checkout-price-detail__item-img {
    width: 32/75rem;
    height: auto;
    object-fit: cover;
  }

  .checkout-price-detail__item-child {
    font-size: 12px;
    color: #767677;

    .checkout-price-detail__child-item {
      margin-bottom: 8/75rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .checkout-price-detail__item-discount {
    background: #F6F6F6;
    margin: 0 -24/75rem;
    padding: 16/75rem 24/75rem;
    border-top: 1px solid #e5e5e5;
  }

  .checkout-price-detail__total {
    padding-top: 16/75rem;

    .checkout-price-detail__total-left {
      font-weight: 600;
      flex-shrink: 0;
      max-width: 128px;
      margin-right: 24/75rem;
    }
    .checkout-price-detail__total-right {
      color: #fa6338;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .checkout-price-detail__total-amount {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        font-size: 16px;
        font-weight: 700;
      }

      .checkout-price-detail__total-amount-all {
        font-size: 24px;
      }
    }
  }

  .checkout-price-detail__flex-end {
    align-items: flex-end;
  }

  .checkout-price-detail__total-old {
    border-top: 1px solid #e5e5e5;
  }
}

.checkout-price-detail__dialog {
  text-align: center;
}
</style>
