<template>
  <!--新流程：勾选优惠券 优惠信息汇总提示-->
  <transition name="vue-slide-layer-bottom-fade">
    <div
      v-show="isShowCouponUseInfo"
      class="bsc-coupon-list__use-info"
    >
      <div
        class="bsc-coupon-list__use-info_tip"
        tabindex="0"
      >
        <span class="bsc-coupon-list__use-info_check-tips">
          {{ _commonTemplate(applyCouponsSuccess && applyCouponsSuccess.length || '', language?.SHEIN_KEY_PWA_20994) }}
        </span>
        <span
          class="bsc-coupon-list__use-info_amount-wrap"
          :aria-label="_commonTemplate(checkCouponPrice || '',language?.SHEIN_KEY_PWA_17288)"
        >
          <div
            v-if="checkCouponPrice"
          >
            {{ _commonTemplate('', language?.SHEIN_KEY_PWA_17288) }}
            <span class="amount direction-ltr">
              <template v-for="(digit,index) in checkCouponPrice">
                <NumRun
                  v-if="parseInt(digit) >= 0"
                  :key="index"
                  :value="parseInt(digit)"
                />
                <span
                  v-else
                  :key="index"
                >{{ digit }}</span>
              </template>
            </span>
          </div>
          <span
            v-show="isFreeShippingRef && isFreePrice"
            class="bsc-coupon-list__use-info_free-tip"
          >{{ language?.SHEIN_KEY_PWA_21002 }}</span>
          <div
            v-show="selectCouponHasGift"
            class="bsc-coupon-list__use-info_gift-tip"
          >{{ language?.SHEIN_KEY_PWA_17978 }}</div>
        </span>
      </div>
      <SButton
        :type="['H80PX','primary']"
        style="width: 100%"
        @click="handleClickUseCoupon"
      >
        {{ language?.SHEIN_KEY_PWA_21001 }}
      </SButton>
    </div>
  </transition>
</template>
<script setup lang="ts">
  import type { PROP_CouponList, RES_CouponList, C_CouponList } from '../../../types'
  import { template as _commonTemplate } from '@shein/common-function'
  import { Button as SButton } from '@shein/sui-mobile'
  import { computed, inject } from 'vue'
  import NumRun from '../components/numRun.vue'
  import useState from '../hooks/useState.ts'

  const { triggerNotice } = inject('analysisInstance') as C_CouponList.AnalysisInstance

  const emit = defineEmits(['confirm-use-coupon-handle'])
  const { selectCouponHasGift, isFreeShippingRef } = useState

  const props = defineProps<{
    isShowCouponUseInfo: boolean | number | null | undefined,
    couponTotalPrice: PROP_CouponList.Price,
    isFreePrice: boolean | number | string,
    applyCouponsSuccess: Array<RES_CouponList.CommonCoupon> | [] | undefined | null,
    language: any,
  }>()

  // 计算优惠券价格
  const checkCouponPrice = computed(() => {
    const amount = props.couponTotalPrice?.amount
    const amountWithSymbol= props.couponTotalPrice?.amountWithSymbol
    return (amount && +amount > 0) ? amountWithSymbol : ''
  })

  // 点击使用最优券事件处理
  const handleClickUseCoupon = () => {
    triggerNotice && triggerNotice({
      id: 'click_couponconfirm.comp_coupon-list',
      data: {
        coupon_code: props.applyCouponsSuccess?.map(item => item.couponCode.toLowerCase())?.join(),
        result: 1,
        num_checked_coupon: props.applyCouponsSuccess?.length,
      },
    })
    emit('confirm-use-coupon-handle')
  }
</script>
<style lang="less">
.bsc-coupon-list__use-info{
  padding-bottom: 0.64rem;
  z-index: 3;  // 券组件部分元素z-index=3，这里需要覆盖券组件元素

  &_tip{
      padding: 24/75rem 0 0;
      font-size: 12px;
      color: #767676;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  &_free-tip {
    color: #fa6338;
    display: inline-block;
    font-size: 12px;
    font-weight: 400
  }

  &_check-tips{
    max-width: 5.33rem;
  }

  &_amount-wrap{
     font-weight: bold;
    font-size: 16px;
     color: #222222;
    text-align: right;
     .amount{
       color: #FA6338;
     }
   }

  button{
    text-transform: uppercase;
    margin-top: 24/75rem;
  }

  &_gift-tip{
    margin-bottom: 12/75rem;
    text-align: right;
    font-size: 12px;
    color: #FF7300;
    font-weight: 400;
  }
}
</style>
