
import { countryOrAreaIdMap } from './common'

/**
 * @description: 设置地址名称
 * @param {Record} detail 地址具体的数据
 * @return {*} 按照格式 返回名称
 */
export const getAddressName = (detail: Record<string, any> = {}): string => {
  const addressbookName: Record<string, string[]> = {
    [countryOrAreaIdMap.TW]: ['lname', 'fname'],
    [countryOrAreaIdMap.JP]: ['lname', 'fname'],
    default: ['fname', 'middleName', 'lname', 'fatherName'],
  }

  const { countryId, shipping_country_id, subsidiary } = detail
  const currentId = countryId || shipping_country_id
  const order = addressbookName[currentId] || addressbookName.default

  return order.map(item => detail[item] || (subsidiary && subsidiary[item])).filter(Boolean).join(' ')
}
