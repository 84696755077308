import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BAddresslist } from './addresslist.vue'
import type { DS_Addresslist } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getAddresslistFsDataRunner(sourceParams?: Partial<DS_Addresslist.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
