<template>
  <!-- 列表主体 -->
  <div class="bsc-coupon-list">
    <div>
      <!-- Tab: 可用券/不可用券 -->
      <STab
        v-model="unusedCouponShow"
        @change="swtichTab"
      >
        <STabItem
          :id="true"
          :aria-label="language?.SHEIN_KEY_PWA_17182"
        >
          {{ language?.SHEIN_KEY_PWA_17182 }}
        </STabItem>
        <STabItem
          :id="false"
          :aria-label="language?.SHEIN_KEY_PWA_17183"
        >
          {{ language?.SHEIN_KEY_PWA_17183 }}
        </STabItem>
      </STab>

      <!-- Tip: 每单限用一张券 -->
      <NoticeTip :couponListNotice="couponListNotice" />

      <!-- Coupon List -->
      <div v-if="isShowCouponList">

        <!-- Coupon List: 可用券 -->
        <!-- @vue-ignore -->
        <section
          v-if="isTabLoaded.tab1"
          v-show="unusedCouponShow"
          class="bsc-coupon-list__coupon-wrap"
        >
          <!-- 用券功能 -->
          <div
            v-if="couponListScene == COUPON_LIST_SCENE.NOMAL_CHECKOUT"
            class="bsc-coupon-list__coupon-wrap_apply-container bsc-coupon-list__pd-32rem"
          >
            <div class="bsc-coupon-list__coupon-wrap_apply-content">
              <input
                v-model="couponCodeRef"
                type="text"
                name="coupon"
                class="mshe-input bsc-coupon-list__coupon-wrap_code-input"
                :placeholder="htmlDecode({ text: language?.SHEIN_KEY_PWA_15095 })"
                @focus="handleConfirmUse(0)"
                @blur="handleConfirmUse(1)"
              />
              <button
                v-enterkey
                class="mshe-btn-sec mshe-btn-s bsc-coupon-list__coupon-wrap_code-apply"
                :class="{'bsc-coupon-list__coupon-wrap_max-width': lang == 'fr'}"
                @click="handleApplyCoupon"
              >
                {{ checkAfterCoupon ? language?.SHEIN_KEY_PWA_16216 : language?.SHEIN_KEY_PWA_15104 }}
              </button>
            </div>
            <p
              v-show="isShowErrorTip"
              class="bsc-coupon-list__coupon-wrap_err-info"
              tabindex="0"
            >
              <!-- @vue-ignore -->
              {{ htmlDecode({ text: (errinfo || couponInfoErr) }) }}
            </p>
          </div>

          <!-- 券列表 -->
          <ul
            v-if="usableCouponList && usableCouponList.length"
            v-cloak
          >
            <!-- 手动增加的券 -->
            <template v-if="addedCouponListRef && addedCouponListRef.length > 0">
              <ClassifyCouponWrap
                :title="language?.SHEIN_KEY_PWA_21000"
                :couponType="COUPON_TYPE.ADD_COUPON"
                :listData="addedCouponListRef"
                :language="language"
                :keyStr="'added-coupon-item'"
              >
                <template
                  #new-coupon-item="{
                    data,
                    couponConfig,
                    index,
                    checkoutCouponIndex,
                    couponType,
                  }"
                >
                  <slot
                    name="new-coupon-item"
                    v-bind="{
                      data,
                      couponConfig,
                      index,
                      checkoutCouponIndex,
                      couponType,
                    }"
                  ></slot>
                </template>
              </ClassifyCouponWrap>
            </template>

            <!-- 最优券组合 -->
            <template v-if="bestCouponListRef && bestCouponListRef.length">
              <ClassifyCouponWrap
                :title="language?.SHEIN_KEY_PWA_25058"
                :couponType="COUPON_TYPE.BEST_COUPON"
                :listData="bestCouponListRef"
                :bestCouponInfoRef="bestCouponInfoRef"
                :language="language"
                :keyStr="'unuse-best-coupon-item'"
                @use-best-coupon="useBestCoupon"
              >
                <template
                  #new-coupon-item="{
                    data,
                    couponConfig,
                    index,
                    checkoutCouponIndex,
                    couponType,
                  }"
                >
                  <slot
                    name="new-coupon-item"
                    v-bind="{
                      data,
                      couponConfig,
                      index,
                      checkoutCouponIndex,
                      couponType,
                    }"
                  ></slot>
                </template>
              </ClassifyCouponWrap>
            </template>

            <!-- Coupon List: 未用券 -->
            <template v-if="commonCouponListRef && commonCouponListRef.length">
              <ClassifyCouponWrap
                :title="commonCouponTitle"
                :couponType="COUPON_TYPE.COMMON_COUPON"
                :listData="commonCouponListRef"
                :language="language"
                :keyStr="'unuse-coupon-item-'"
              >
                <template
                  #new-coupon-item="{
                    data,
                    couponConfig,
                    index,
                    checkoutCouponIndex,
                    couponType,
                  }"
                >
                  <slot
                    name="new-coupon-item"
                    v-bind="{
                      data,
                      couponConfig,
                      index,
                      checkoutCouponIndex,
                      couponType,
                    }"
                  ></slot>
                </template>
              </ClassifyCouponWrap>
            </template>

            <div
              v-if="usableCouponList.length > 5"
              class="bsc-coupon-list__coupon-nomore"
              style="margin-top:.64rem"
            >
              - {{ language?.SHEIN_KEY_PWA_18263 }} -
            </div>
          </ul>
          <SEmptyStateView
            v-else
            :content="language?.SHEIN_KEY_PWA_15819"
          >
            <slot name="icon">
              <Icon
                name="sui_icon_vip_konglihe_75px_1"
                size="75px"
              />
            </slot>
          </SEmptyStateView>
        </section>

        <!-- Coupon List: 不可用券 -->
        <section
          v-if="isTabLoaded.tab2"
          v-show="!unusedCouponShow"
          id="j-expire-coupon-wrap"
          class="bsc-coupon-list__coupon-wrap"
        >
          <!-- Coupon List: 不可用券 -->
          <template v-if="disabledCouponListRef && disabledCouponListRef.length > 0">
            <!-- 普通不可用 -->
            <ClassifyCouponWrap
              :title="language?.SHEIN_KEY_PWA_15797"
              :couponType="COUPON_TYPE.COMMON_DISABLE"
              :listData="disabledCouponListRef"
              :language="language"
              :keyStr="'disabled-common-coupon'"
            >
              <template
                #new-coupon-item="{
                  data,
                  couponConfig,
                  index,
                  checkoutCouponIndex,
                  couponType,
                }"
              >
                <slot
                  name="new-coupon-item"
                  v-bind="{
                    data,
                    couponConfig,
                    index,
                    checkoutCouponIndex,
                    couponType,
                  }"
                ></slot>
              </template>
            </ClassifyCouponWrap>
            <div
              v-if="disabledCouponListRef.length > 5"
              class="bsc-coupon-list__coupon-nomore"
            >
              - {{ language?.SHEIN_KEY_PWA_18263 }} -
            </div>
          </template>
          <SEmptyStateView
            v-else
            :content="language?.SHEIN_KEY_PWA_15819"
          >
            <slot name="icon">
              <Icon
                name="sui_icon_vip_konglihe_75px_1"
                size="75px"
              />
            </slot>
          </SEmptyStateView>
        </section>
      </div>
    </div>
  </div>
</template>
<script name="NumRun" setup lang="ts">
import { computed, ref, onMounted, inject, nextTick } from 'vue'
import { template as _commonTemplate, htmlDecode } from '@shein/common-function'
import type { RES_CouponList, C_CouponList, PROP_CouponList } from '../../../types/index'
import { Toast as $toast, EmptyStateView as SEmptyStateView, Tab as STab, TabItem as STabItem } from '@shein/sui-mobile'
import { isToLowerCase } from '../../../common/utils/index.js'
import useApis from '../../../hooks/useApis.js'
import { COUPON_HANDLE_TYPE, COUPON_LIST_SCENE } from '../../../common/utils/constants.js'
import NoticeTip from './notice-tip.vue'
import ClassifyCouponWrap from './classify-coupon-wrap.vue'
import { COUPON_TYPE } from '../../../common/utils/constants.js'
import { getQueryString } from '@shein/common-function'

import useState from '../hooks/useState.ts'
import { useCoupon } from '../hooks/useCoupon.ts'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useTabLoaded } from '../hooks/useRenewCouponList.ts'

const appConfigs = useAppConfigs()
const {triggerNotice} = inject('analysisInstance') as C_CouponList.AnalysisInstance

const {
  unusedCouponShow,
  couponCodeRef,
  usableCouponList,
  isFreeShippingRef,
  selectCouponHasGift,
  checkCouponsRef,
  commonCouponListRef,
  addedCouponListRef,
  primeCouponListRef,
  bestCouponListRef,
  disabledCouponListRef,
  isLoaded,
  couponListNotice,
  alreadyExposeCouponInfo,
  isClickPrimeMoreRef,
  couponInfoVal,
  couponInfoLastCoupon,
  couponInfoErr,
  couponInfoLastAddCoupon,
  couponsApplyRef,
  bestCouponInfoRef,
  isManualUseCoupon,
  cacheTargeCode,
} = useState
const { handleAutoAnchored, insertCouponGoodsUsable, insertCouponLimitGoods, getCouponArrFromSucc, newApplyCouponCB } = useCoupon()
const { getCouponListApi } = useApis()

/******************* emit ********************/
const emit = defineEmits([
  'handle-best-apply-btn',
  'handle-new-apply-btn',
  'change-confirm-coupon',
  'change-loading',
  'change-tab',
  'handle-gift--coupon-operation',
])

const { isTabLoaded, handleTabSwitch } = useTabLoaded(unusedCouponShow, computed(() => props.visible))

/******************* Props ********************/
const props = defineProps<{
  language: any,
  errinfo: string,
  // isFreePrice: object | string | boolean | null,
  checkoutNo: string | boolean | object | null, // 立即购单号（为空默认走正常下单逻辑)
  selectedPaymentInfo: PROP_CouponList.SelectedPaymentInfo | undefined,
  selectedShipMethod?: PROP_CouponList.SelectedPaymentInfo | undefined,
  saveCardProductCode?: string,
  primeProductCode?: string,
  visible: boolean,
  isShowCouponList: boolean,
  isCouponAutoUse: boolean,
  couponListUrl: string, // 优惠券列表接口地址
  applyCouponsSuccess: Array<RES_CouponList.CommonCoupon> | [] | undefined | null,
  couponListScene: string // 券列表组件使用场景
}>()

onMounted(() => {
  isLoaded.value = true
  // getCouponList({})
})

/******************* methods ********************/
const useBestCoupon = () => {
  const coupon = bestCouponListRef.value?.map(item => (item.coupon || '')).join()
  emit('handle-best-apply-btn', coupon, COUPON_HANDLE_TYPE.applyBestCoupon)
}

const handleApplyCoupon = () => {
  isManualUseCoupon.value = true
  if(!couponCodeRef.value) {
    return
  }
  emit('handle-new-apply-btn', { codeStr: couponCodeRef.value, isApply: checkAfterCoupon.value, type: COUPON_HANDLE_TYPE.newApplyCoupin })
}
const swtichTab = ({ val }) => {
  unusedCouponShow.value = val

  handleTabSwitch(val)

  emit('change-tab', val)
}
const lang = computed(() => {
  console.log('appConfigs?.$envs?.lang-----', appConfigs?.$envs?.lang)
  return appConfigs?.$envs?.lang || ''
})
const isShowErrorTip = computed(() => {
  return (props?.errinfo || couponInfoErr) && props?.couponListScene == COUPON_LIST_SCENE.NOMAL_CHECKOUT
})
const checkAfterCoupon = computed(() => {
  return couponCodeRef.value && (couponInfoLastCoupon.value == couponCodeRef.value?.toLowerCase())
})
const commonCouponTitle = computed(() => {
  return (bestCouponListRef.value && bestCouponListRef.value?.length > 0) ? props.language?.SHEIN_KEY_PWA_25061 : props.language?.SHEIN_KEY_PWA_15797
})

  // 使用最优券
const applyBestCoupon = (param, cb) => {
  // applyCouponsSuccess 直接使用外层传入的已使用券
  isManualUseCoupon.value = false
  const couponCode = props.applyCouponsSuccess?.map(item => (item.couponCode || item.coupon || '')).join()
  const newCoupon = couponCode?.toLowerCase()
  couponInfoVal.value = newCoupon

  postCouponListFromBff({
    cb: cb ? cb : null,
    opt: {
      add_coupon: couponInfoLastAddCoupon.value || '',
      last_add_coupon: '',
      coupon_list: newCoupon,
    },
  })
}

// 判断当前用户输入的券码，是否在最优券组合或other coupons组
const judgeAddCoupon = (couponCode) => {
  let isIncludeList = false
  // shein 不需再进行 ab 判断
  bestCouponListRef.value?.forEach(item => {
    if(isToLowerCase(item.coupon, couponCode)) isIncludeList = true
  })
  commonCouponListRef.value?.forEach(item => {
    if(isToLowerCase(item.coupon, couponCode)) isIncludeList = true
  })
  return isIncludeList
}

// 手动用券
const applyCouponCode = async(cb) => {
  triggerNotice && triggerNotice({
    id: 'click_added_coupon.comp_coupon-list',
  })
  const couponCode = couponCodeRef.value?.trim()
  couponInfoVal.value = couponCode
  const isIncludeList = judgeAddCoupon(couponCode)
  if (!couponCode) {
    couponInfoErr.value = props.language?.SHEIN_KEY_PWA_15116
    $toast(props?.language?.SHEIN_KEY_PWA_15116)
    return
  }
  couponsApplyRef.value = !checkAfterCoupon.value && !isIncludeList ? true : false

  newApplyCoupon({coupon: couponCode}, cb)
  // 存在于最优券组合或other coupons组中的手输券，需要增加使用成功锚定功能(apply操作)
  if(checkAfterCoupon.value && isIncludeList) {
    handleAutoAnchored(couponCode)
  }
}

// 判断当前使用券是否是赠品券
const handleGiftCoupon = (cList = '') => {
  let hasGiftCoupon = false
  let couponList = cList?.split(',')
  if(!couponList?.length || !usableCouponList.value?.length) {
    return false
  }
  !hasGiftCoupon && couponList?.map(code => {
    for(let i = 0; i < usableCouponList.value.length; i++) {
      // coupon不同直接走下一个cItem比较
      if(code == usableCouponList.value[i].coupon) {
        if(usableCouponList.value[i].businessExtension?.order?.hasCouponGift == '1') {
          hasGiftCoupon = true
          break
        }
      }
    }
  })
  hasGiftCoupon && emit('handle-gift--coupon-operation')
}

const updateMyCoupons = () => {
  // let alreayFilterOptimalCoupon = false // 同码最优券只过滤一张
  let checkCoupons: Array<RES_CouponList.CheckCouponsItem> = []
  let isFreeShipping = false
  let selectCouponHasGiftFlg = false
  let cpUsableCouponList = JSON.parse(JSON.stringify(usableCouponList.value))
  cpUsableCouponList.forEach((item) => {
    if (item.businessExtension?.order?.isCheck != 0) checkCoupons.push(item)
  })

  if (primeCouponListRef.value.length) {
    primeCouponListRef.value && primeCouponListRef.value.forEach((item:RES_CouponList.CommonCoupon, index) => {
      // 自动选中index > 1的会员券手动展开view more
      if (item?.businessExtension?.order?.isCheck == 1 && index > 1) isClickPrimeMoreRef.value = true
    })
  }
  emit('change-confirm-coupon', (props.applyCouponsSuccess?.length || checkCoupons.length) ? true : false)

  // 判断已选中券是否有免邮券
  if(Array.isArray(usableCouponList.value)) {
    for(let i = 0; i < usableCouponList.value?.length; i++) {
      // 判断选中且是免邮券
      if(usableCouponList.value[i]?.businessExtension?.order?.isCheck == '1' && [5, 6, 9].includes(+usableCouponList.value[i]?.applyFor)) {
        isFreeShipping = true
      }
      // 判断选择且是赠品券
      if(usableCouponList.value[i]?.businessExtension?.order?.isCheck == '1' && usableCouponList.value[i]?.businessExtension?.order?.hasCouponGift == '1') {
        selectCouponHasGiftFlg = true
      }
    }
  }
  isFreeShippingRef.value = isFreeShipping
  selectCouponHasGift.value = selectCouponHasGiftFlg
  checkCouponsRef.value = checkCoupons
  // handlePrimeData()
}

// const handlePrimeData = () => {
//   const primeCoupon = props.applyCouponsSuccess?.filter(item => item.applyFor == 9 && item.type_id == 53) || []
//   if (primeCoupon.length) {
//     commonCouponListRef.value = commonCouponListRef.value?.map(item => {
//       // 非会员券，跳过
//       if (item.real_type_id != 53) return item
//       const newData = props.isFreePrice ? { is_gray: 1 } : { is_over: 1 }
//       if (props.isFreePrice) {
//         if (item.businessExtension?.order?.isCheck == 1) {
//           return item
//         } else {
//           return item = Object.assign({}, item, newData)
//         }
//       } else {
//         if (item.apply_for == 9 && item.real_type_id == 53 && item.shipping_discount_type == 1 && item.businessExtension?.order?.isCheck != 1) {
//           return item = Object.assign({}, item, newData)
//         } else {
//           return item
//         }
//       }
//     })
//   }
// }

const exposeRecommendCouponInfo = (res) => {
  if (alreadyExposeCouponInfo.value) return
  const auto_coupon = getQueryString({ key: 'auto_coupon' }) || ''
  let auto_coupon_res = '-'
  if(!props?.isCouponAutoUse) {
     auto_coupon_res = props.applyCouponsSuccess?.length ? '2' : '0'
  } else {
    if (!props.applyCouponsSuccess?.length) {
      auto_coupon_res = '1'
    } else {
      auto_coupon_res = props.applyCouponsSuccess?.map(item => item.couponCode?.toLowerCase())?.join(',') == auto_coupon.toLowerCase() ? '4' : '3'
    }
  }
  triggerNotice && triggerNotice({
    id: 'expose_automatically_coupons_result.comp_coupon-list',
    data: {
      automatically_result: auto_coupon_res,
      },
  })

  triggerNotice && triggerNotice({
    id: 'expose_recommend_coupon_info.comp_coupon-list',
    data: {
        coupon_info: res.bestCoupon ? res.bestCoupon?.is_real_best : '-',
      },
  })
  alreadyExposeCouponInfo.value = true
}

// 获取券列表
const postCouponListFromBff = async (opts: C_CouponList.OptType) => {
  // notTips 用券错误、用礼品卡错误，是否不提示toast
  let { cb, opt } = opts
  let params = {
    ...opt,
    is_now_buy: props.checkoutNo ? 1 : 0, // 是否为立即购买,0-否,1-是,非1场景均按照非立即购处理
    checkout_no: props.checkoutNo,
    enableCouponCmp: 1, //1代表中间层接口返回新的券组件对应的bff券数据
    is_old_version: 0, // 默认写死
    mall_params: JSON.stringify(opt?.mall_params),
    payment_code_unique: props.selectedPaymentInfo?.payment_code || '',
    payment_id: props.selectedPaymentInfo?.payment_id || '',
    shipping_methods: props?.selectedShipMethod || [],
    save_card_product_code: props?.saveCardProductCode || '',
    prime_product_code: props?.primeProductCode || '',
  }

  switch (props?.couponListScene) {
    case COUPON_LIST_SCENE.CASHIER_PRIME:
      params.right_coupon_type = 'prime_right'
      break
    case COUPON_LIST_SCENE.CASHIER_SAVE_CARD:
      params.right_coupon_type = 'save_card_right'
      break
    default:
      break
  }

  // bff接口使用coupon_list而不是coupon
  params.coupon_list = typeof params?.coupon_list == 'string' ? params?.coupon_list?.split(',') : params?.coupon_list
  emit('change-loading', true)

  getCouponListApi(props.couponListUrl, params).then((res) => {
    emit('change-loading', false)
    if((res as RES_CouponList.RES_DATA)?.code == 0) {
      // nextTick 是为了等前置的state更新完成
      // 否则可能会影响后置逻辑取state，因为state的更新是异步的
      // 券提示
      couponListNotice.value = (res as RES_CouponList.RES_DATA)?.info?.couponNoticeTip || ''
      // 所有可用券
      usableCouponList.value = (res as RES_CouponList.RES_DATA)?.info?.cmpUsableCoupons.concat((res as RES_CouponList.RES_DATA)?.info?.cmpAddCouponCoupons || []).concat((res as RES_CouponList.RES_DATA)?.info?.cmpClubCoupons || []).concat((res as RES_CouponList.RES_DATA)?.info?.cmpBestCombinationCoupons || []) || []
      // 普通可用券
      commonCouponListRef.value = (res as RES_CouponList.RES_DATA)?.info?.cmpUsableCoupons || []
      //手动增加的券
      // todo
      addedCouponListRef.value = (res as RES_CouponList.RES_DATA)?.info?.cmpAddCouponCoupons || [];
      // 最后添加的券
      (res as RES_CouponList.RES_DATA)?.info?.cmpAddCouponCoupons?.forEach(item => {
        couponInfoLastAddCoupon.value = item.couponCode || item.coupon
      })
      // 会员券
      primeCouponListRef.value = (res as RES_CouponList.RES_DATA)?.info?.cmpClubCoupons || []
      // 最优券
      // todo
      let best = JSON.parse(JSON.stringify((res as RES_CouponList.RES_DATA)?.info?.cmpBestCombinationCoupons))
      bestCouponListRef.value = ref(best)
      // 最优券信息
      bestCouponInfoRef.value = (res as RES_CouponList.RES_DATA)?.info?.bestCoupon
      // 不可用券
      disabledCouponListRef.value = (res as RES_CouponList.RES_DATA)?.info?.cmpDisableCoupons
      const lastCoupon = couponInfoVal.value || ''
      const applyCoupons = props.applyCouponsSuccess?.map(item => item.couponCode.toLowerCase()) || []
      const lastCouponSuccess = applyCoupons.includes(lastCoupon?.toLowerCase())
      const lastValue = lastCouponSuccess ? lastCoupon : ''
      couponInfoLastCoupon.value = lastValue
      couponCodeRef.value = lastValue
      exposeRecommendCouponInfo(res)
      updateMyCoupons()
      handleGiftCoupon(lastCoupon)
    }
    nextTick(() => {
      cb && cb((res as RES_CouponList.RES_DATA)?.info)

      // 滚动到已选券
      let scrollTargetCoupon = couponInfoVal.value?.split(',')?.[0] || ''
      isManualUseCoupon.value && scrollTargetCoupon && newApplyCouponCB(scrollTargetCoupon)
    })
  }).catch(() => {
    emit('change-loading', false)
  })
}

const newApplyCoupon = (item, cb) => {
  const { coupon } = item
  // 当前状态是否选中
  const isCheck = item?.businessExtension?.order?.isCheck == '1'
  const newCoupon = coupon.toLowerCase()
  !isCheck && (couponInfoVal.value = newCoupon)
  couponInfoErr.value = ''
  let couponArr = props.applyCouponsSuccess?.map(i => {
    return i.couponCode || i.coupon
  })
  // 判断优惠券是否已被选中
  // if(isCheck) {
  //   couponArr = couponArr?.filter(i => i != coupon)
  // } else {
  //   // 是可叠加券
  //   if(Array.isArray(item?.promptInfo) && item?.promptInfo?.includes('stackable')) {
  //     couponArr?.push(item.coupon)
  //   } else {
  //     let couponString = item.couponCode || item.coupon
  //     couponArr = couponString.split(',')
  //   }
  // }

  postCouponListFromBff({
    cb: cb ? cb : null,
    opt: {
      add_coupon: couponsApplyRef.value ? newCoupon : couponInfoLastAddCoupon.value,
      last_add_coupon: (isCheck || checkAfterCoupon.value) ? '' : newCoupon, // clic点击取消用券时传空
      coupon_list: couponArr,
    },
  })
}

  /**
 * @description 获取券列表总入口函数
 * @param {string} handleType // 更新券列表类型
 * @returns {null}
 */
const getCouponList = async (options) => {
  const { handleType, item, add_coupon = '', cb } = options
  let cList = !handleType && (getCouponArrFromSucc(props.applyCouponsSuccess) || [])
  switch (handleType) {
    // 使用最佳优惠券  'applyBestCoupon'
    case COUPON_HANDLE_TYPE.applyBestCoupon:
      couponsApplyRef.value = false
      applyBestCoupon({}, cb)
      break

    //手动用券  'newApplyCoupin'
    case COUPON_HANDLE_TYPE.newApplyCoupin:
      applyCouponCode(cb)
      break

    // 点击选择优惠券  checked 0, 1 ===> 取消, 勾选
    case COUPON_HANDLE_TYPE.clickSelectCoupon:
      couponsApplyRef.value = false
      isManualUseCoupon.value = false
      newApplyCoupon(item, cb)
      break

    default:
      postCouponListFromBff({
        cb: cb ? cb : null,
        opt: {
          add_coupon,
          coupon_list: cList,
        },
      })
  }
}

const handleConfirmUse = (type) => {
  emit('change-confirm-coupon', type)
}

const scrollToTargeCoupon = (code) => {
  cacheTargeCode.value = code
  // newApplyCouponCB(code)
}

/******************* Expose ********************/
defineExpose({
  getCouponList,
  insertCouponLimitGoods,
  insertCouponGoodsUsable,
  scrollToTargeCoupon,
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
 transition: all 0.5s;
}
.bsc-coupon-list {
  padding-top: 1.17rem;
  font-size: 11px;
  &__pd-32rem {
    padding-left: .32rem;
    padding-right: .32rem;
  }
  &__coupon-wrap{
    padding: .32rem 0 0;
    overflow-y: scroll;
    &_recommend-tips{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -20/75rem 0 20/75rem;
      i {
        margin-right: (8/75rem);
        color: #222;
      }
      em {
        color: #C44A01;
      }
    }
    &_item-new {
      margin-bottom: 0.52rem;
    }
    &_recommend-btn{
      margin-left: (8/75rem);
      flex-shrink: 0;
    }
    &_coupon-title{
      font-size: 14px;
      margin: 40/75rem 0 .26666rem;
      font-family: 'adieu';
    }
    &_apply-container{
      margin: 8/75rem 0 32/75rem;
    }
    &_apply-content{
      height: 0.96rem;
      display: flex;
      justify-content: space-between;
    }
    &_code-apply{
      height: 100%;
      width: 3rem;
      background-color: #222;
      color: #fff;
      font-size: 14px;
      font-weight:500;
      text-transform: uppercase;
      font-family: 'adieu';
    }
    &_max-width{
       width: 3.6rem;
    }
    &_code-input{
      flex: 1;
      margin-right: 0.32rem;
      padding-left: 0.32rem;
      border: none;
      background: #F6F6F6;
      font-size: 14px;
      &::placeholder{
        color: #ccc;
      }
    }
    &_err-info{
      color: #bf4123;
      font-size: 12px;
      font-weight:400;
      margin-top: 0.2133333rem;
    }
  }
  &__coupon-nomore{
    text-align: center;
    margin: .64rem;
    color: #999;
    margin-top: 1rem;
  }
}
</style>
